import React, { useEffect, useState } from "react"
import {
    Switch,
    Route,
    useHistory,
    Redirect,
    useLocation,
} from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

import { BackButtonTableTop as GoBack } from "components/BackButtonTableTop"
import { TableHeaderButtons as GroupedButtons } from "components/TableHeaderButtons"
import { Button } from "components/Button"
import { APP_ROUTES } from "helpers/routeHelpers"
import DepartmentRequestListing from "./InventoryReq-DepartmentReqListing"
import PullbackRequestListing from "./InventoryReq-PullbackReqListing"
import ScrapReturnRequestListing from "./InventoryReq-ScrapReturn"
import InventoryReqTheft from "./InventoryReq-Theft"

const InventoryRequests = ({}) => {
    let history = useHistory()
    let location = useLocation()

    const [isActive, setIsActive] = useState({
        departmentRequest: true,
        pullBackRequest: false,
        scrapReturnRequest: false,
        theft: false,
    })

    useEffect(() => {
        setIsActive({
            departmentRequest:
                location.pathname == APP_ROUTES.INVENTORY_REQUEST_DEPARTMENT,
            pullBackRequest:
                location.pathname == APP_ROUTES.INVENTORY_REQUEST_PULLBACK,
            scrapReturnRequest:
                location.pathname == APP_ROUTES.INVENTORY_REQUEST_SCRAP,
            theft: location.pathname == APP_ROUTES.INVENTORY_REQUEST_THEFT,
        })
    }, [location])

    const handleDepartmentRequest = () => {
        history.push(APP_ROUTES.INVENTORY_REQUEST_DEPARTMENT)
    }
    const handlePullBackRequest = () => {
        history.push(APP_ROUTES.INVENTORY_REQUEST_PULLBACK)
    }
    const handleScrapReturnRequest = () => {
        history.push(APP_ROUTES.INVENTORY_REQUEST_SCRAP)
    }
    const handleTheft = () => {
        history.push(APP_ROUTES.INVENTORY_REQUEST_THEFT)
    }

    const mainBtnsConfig = [
        {
            title: "Station Requests",
            onClick: handleDepartmentRequest,
            classesButton: "ms-0 ms-md-2",
            identifier: "departmentRequest",
        },
        {
            title: "Transfer Inventory Request",
            onClick: handlePullBackRequest,
            classesButton: "ms-2",
            identifier: "pullBackRequest",
        },
        {
            title: "Scrap / Return",
            onClick: handleScrapReturnRequest,
            classesButton: "ms-2",
            identifier: "scrapReturnRequest",
        },
        {
            title: "Theft",
            onClick: handleTheft,
            classesButton: "ms-2",
            identifier: "theft",
        },
    ]

    const routesConfig = [
        {
            name: "Station Requests",
            isExact: false,
            component: DepartmentRequestListing,
            path: APP_ROUTES.INVENTORY_REQUEST_DEPARTMENT,
        },
        {
            name: "Transfer Inventory Requests",
            isExact: false,
            component: PullbackRequestListing,
            path: APP_ROUTES.INVENTORY_REQUEST_PULLBACK,
        },
        {
            name: "Scrap/Return Requests",
            isExact: false,
            component: ScrapReturnRequestListing,
            path: APP_ROUTES.INVENTORY_REQUEST_SCRAP,
        },
        {
            name: "Theft",
            isExact: false,
            component: InventoryReqTheft,
            path: APP_ROUTES.INVENTORY_REQUEST_THEFT,
        },
    ]

    return (
        <div className="gt-business-accounts">
            <Container fluid>
                <Row>
                    <Col className="col-12 px-lg-3 px-2">
                        <div className="d-flex align-items-md-center mb-4 mb-4 mt-lg-0 mt-3 flex-column flex-md-row align-items-start">
                            <GoBack />
                            <div>
                                <GroupedButtons
                                    data={mainBtnsConfig}
                                    classes={
                                        " px-2 px-md-4 btn-alignment wh-im-header-inner-btns my-1"
                                    }
                                    isActive={isActive}
                                />
                            </div>
                        </div>

                        <Switch>
                            {routesConfig.map(
                                ({ name, isExact, path, component }, idx) => (
                                    <Route
                                        key={idx}
                                        name={name}
                                        exact={isExact}
                                        path={path}
                                        component={component}
                                    />
                                )
                            )}
                            <Redirect
                                to={APP_ROUTES.INVENTORY_REQUEST_DEPARTMENT}
                            />
                        </Switch>

                        <div className="text-center d-flex justify-content-center align-items-center">
                            <Button
                                size="lg"
                                className="gt-btn-grad-primary table-bottom-btn btn-alignment ln-height-1"
                                title="Back to Main"
                                onClick={history.goBack}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default InventoryRequests
