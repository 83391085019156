import { useSelector } from "react-redux"

const useDepartmentConfig = () => {
    const store = useSelector(state => state)
    const departmentIds = store.userAuth.user.departmentAssignments.map(
        item => item?.departmentId
    )
    const departmentManagerName = store.userAuth.user.departmentName
    const departmentId = Number(
        store?.departmentConfig?.currentSelectedDepartment?.department?.id
    )

    return {
        currentDepartment: store?.departmentConfig?.currentSelectedDepartment,
        currentDepartmentName:
            store?.departmentConfig?.currentSelectedDepartment?.department
                ?.departmentName || "-",
        currentDepartmentId: departmentId && departmentId,
        departmentIds,
        departmentManagerName: departmentManagerName || "-",
    }
}

export default useDepartmentConfig
