import React from "react"
import { useHistory } from "react-router-dom"

import './style.scss'
const BackButtonTableTop = ({
  backarrowCustom= ""
}) => {
  const history = useHistory()
  return (
    <>
      <div className={`back-button-container ps-1 pe-5 mb-3 mb-md-0 ${backarrowCustom}`}>
        <span onClick={history.goBack} className="table-top-back-btn">
          &lt; Back
        </span>
      </div>
    </>
  )
}

export default BackButtonTableTop
